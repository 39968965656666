header {
  padding: 25px 0;
  width: 100%;
  top: 0;
  background: #FFF;
  z-index: 2;
  transition: all 0.3s;
  background: #000000;
}
header nav ul {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}
header nav ul li {
  list-style: none;
  margin: 0;
  padding: 17px;
}
header nav ul li a {
  color: #fff;
  text-decoration: none;
  font-weight: 300;
}
header nav ul li a:hover {
  color: #FFF;
}

#pageDefault {
  margin-top: 75px;
}
#pageDefault .post .image {
  margin: 45px 0;
}
#pageDefault .post .image img {
  width: 100%;
  border-radius: 10px;
}
#pageDefault .post p {
  font-weight: 300;
  margin: auto;
  line-height: 160%;
  margin-bottom: 35px;
  font-size: 18px;
  color: #000;
}
#pageDefault .post ul li {
  font-weight: 300;
  margin: auto;
  line-height: 160%;
  margin-bottom: 35px;
  font-size: 18px;
  color: #000;
}
#pageDefault .post ol li {
  font-weight: 300;
  margin: auto;
  line-height: 160%;
  margin-bottom: 35px;
  font-size: 18px;
  color: #000;
}
#pageDefault .share {
  margin: 75px 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}
#pageDefault .share button {
  color: #fff;
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 14px;
  display: block;
  background-repeat: no-repeat;
  background-position: 25px;
  list-style: none;
  background-color: #000;
  height: 48px;
  border-radius: 80px;
  text-decoration: none !important;
  font-size: 0;
  width: 48px;
  background-position: center;
  border: 0;
}
#pageDefault .share button svg {
  width: 17px;
}
#pageDefault .share button svg path {
  fill: #fff;
}

@media (max-width: 992px) {
  header #button-menu {
    border: 0;
    background: transparent;
    padding-right: 15px;
  }
  header #button-menu div {
    width: 30px;
    height: 3px;
    background: #fff;
    margin: 5px 0;
  }
  header nav {
    display: none;
  }
  header .text-right {
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
  .menu-mobile {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 99999;
    background: #000;
    transform: translateX(100%);
    transition: all 0.3s;
  }
  .menu-mobile .center {
    height: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu-mobile .button-menu-close {
    background: transparent;
    border: 0;
  }
  .menu-mobile .button-menu-close div {
    width: 27px;
    height: 3px;
    background: #fff;
  }
  .menu-mobile .button-menu-close div:nth-child(1) {
    transform: rotate(45deg) translateX(2px) translateY(2px);
  }
  .menu-mobile .button-menu-close div:nth-child(2) {
    transform: rotate(-45deg);
  }
  .menu-mobile ul {
    margin: 0;
    padding: 25px;
    display: flex;
    flex-direction: column;
    height: 60%;
    justify-content: space-evenly;
  }
  .menu-mobile ul li {
    list-style: none;
    text-align: center;
    margin-bottom: 20px;
  }
  .menu-mobile ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }
  .menu-mobile ul li a:hover {
    color: #fff;
    text-decoration: none;
  }
  .menu-mobile.active {
    transform: translateX(0);
  }
}